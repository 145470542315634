import Vue from 'vue'

export default Vue.extend({
  name: 'XpBgImg',
  props: {
    tag: {
      type: String,
      required: true
    },
    src: String,
    placeholderSrc: String,
    lazyload: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      currentSrc: '',
      image: null,
      isLoading: !!this.src,
      hasError: false,
      intersectionObserver: null
    }
  },
  watch: {
    src () {
      this.load()
    }
  },
  computed: {
    url () {
      return this.src || undefined
    },
    style () {
      return {
        backgroundImage: 'url(' + this.currentSrc + ')'
      }
    },
    lowstyle () {
      return {
        backgroundImage: 'url(' + this.placeholderSrc + ')'
      }
    }
  },
  methods: {
    __onLoad () {
      this.isLoading = false
      this.hasError = false
      this.__updateSrc()
      this.$emit('load', this.currentSrc)
    },
    __onError (err) {
      // console.warn(err)
      this.isLoading = false
      this.hasError = true
      this.currentSrc = ''
      this.$emit('error', err)
    },
    __updateSrc () {
      if (this.image !== undefined && this.isLoading === false) {
        const src = this.image.currentSrc || this.image.src
        if (this.currentSrc !== src) {
          this.currentSrc = src
          // this.$forceUpdate()
        }
      }
    },
    __load () {
      this.hasError = false
      if (!this.src) {
        this.isLoading = false
        this.image = undefined
        this.currentSrc = ''
        return
      }
      this.isLoading = true
      const img = new Image()
      this.image = img

      this.$xp.http.getFileUrl(this.src)
        .then(data => {
          if (this.destroyed === true) {
            return
          }
          img.src = data
          // if we are still rendering same image
          if (this.image === img) {
            this.__onLoad(img)
          }
        })
        .catch(error => {
          // if we are still rendering same image
          if (this.image === img && this.destroyed !== true) {
            this.__onError(error)
          }
        })
    },
    __unload () {
      this.image = undefined
      this.currentSrc = ''
    }
  },
  render (createElement) {
    return createElement( // Element with Low-Fi Source
      this.tag,
      {
        style: this.lowstyle,
        attrs: {
          'data-binary-src': this.placeholderSrc,
          'aria-hidden': true
        }
      },
      [createElement( // BG-Element with optimal Source
        'div',
        {
          style: this.style,
          attrs: {
            'data-binary-src': this.src,
            'aria-hidden': true
          }
        })]
    )
  },
  beforeMount () {
    if (this.placeholderSrc !== undefined) {
      const img = new Image()
      img.src = this.placeholderSrc
    }
    this.isLoading = !(this.lazyload && window.IntersectionObserver)
  },
  mounted () {
    if (this.lazyload && window.IntersectionObserver) {
      const options = {
        root: document.querySelector('.c-page-container'),
        rootMargin: '0px',
        threshold: 0
      }
      this.intersectionObserver = new IntersectionObserver((entries) => {
        if (entries[0].intersectionRatio > 0) {
          this.__load()
        } else {
          // this.__unload() // unload raus, weil Problem Performance aeltere Geraete
        }
      }, options)
      this.intersectionObserver.observe(this.$el)
    } else {
      this.__load()
    }
  },
  beforeDestroy () {
    this.destroyed = true
    if (this.intersectionObserver) {
      this.intersectionObserver.unobserve(this.$el)
      this.intersectionObserver = null
    }
    this.isLoading && this.$xp.http.cancelRequest(this.src)
    if (this.image) this.image.src = ''
  }
})
